import { Button, RadioButton } from "@myloc/myloc-gui";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "../../../language/i18n";
import { setOrder } from "../../../reducers/appData/appDataActions";
import { setError, setMessage } from "../../../reducers/dialog/dialogAction";
import clientService from "../../../services/client/clientService";
import deliveryMethodService from "../../../services/deliveryMethod/deliveryMethodService";
import orderService from "../../../services/order/orderService";
import { ADDRESS_TYPE, CLIENT_TYPE, CONTACT_TYPE } from "../../../utils/constants";
import ContactPerson from "../../shared/AddressAndContact/ContactPerson/ContactPerson";
import FacilityAddress from "../../shared/AddressAndContact/FacilityAddress/FacilityAddress";
import FacilityAddressInfo from "../../shared/AddressAndContact/FacilityAddressInfo/FacilityAddressInfo";
import HULAddress from "../../shared/AddressAndContact/HULAddress/HULAddress";
import DeliveryMethods from "../../shared/AddressAndContact/UserAddress/DeliveryMethods/DeliveryMethods";
import UserAddress from "../../shared/AddressAndContact/UserAddress/UserAddress";
import Modal from "../../shared/Modals/Modal";
import { AdvanceContactInformation } from "../../shared/RecieverInformation/RecieverInformation";
import styles from "./CartModals.module.scss";

function AddressAndContactModal({
  visible,
  onClose,
  disableFacility,
  showHUL,
  data,
  productList,
  showDeliveryMethods = false,
  fetchOrders,
}) {
  const translate = useTranslate();
  const appDataUser = useSelector(state => state.appData.user);
  const receiver = useSelector(state => state.appData.order?.receiver);
  const isActiveReferencedOrder = useSelector(state => state.appData?.referencedOrder);
  const [receiverAddressType, setReceiverAddressType] = useState(
    receiver?.type === CLIENT_TYPE.BASE_STORAGE ? ADDRESS_TYPE.COMPANY : ADDRESS_TYPE.CLIENT,
  );
  const [clientAddresses, setClientAddresses] = useState([]);
  const [clientContacts, setClientContacts] = useState([]);
  const [selectedClientAddressId, setSelectedClientAddressId] = useState();
  const [selectedFacilityAddress, setSelectedFacilityAddress] = useState();
  const [selectedContactId, setSelectedContactId] = useState();
  const [deliveryMethods, setDeliveryMethods] = useState([]);
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [useAdvanceContact, setUseAdvanceContact] = useState(receiver?.useAdvanceContact);
  const [showRequired, setShowRequired] = useState(false);
  const orderId = useSelector(state => state.appData?.order?.id);

  useEffect(() => {
    if (!deliveryMethods?.length) {
      deliveryMethodService.getDeliveryMethods({ all: true }).then(response => setDeliveryMethods(response.data.page));
    }
  }, [deliveryMethods]);

  //Load
  const reloadClientAddresses = useCallback(async () => {
    if (receiver?.type === CLIENT_TYPE.USER) {
      const addresses = await loadClientAddresses(receiver, isActiveReferencedOrder);
      if (addresses?.length !== 0) setClientAddresses(addresses?.addresses);
    }
  }, [receiver, isActiveReferencedOrder]);

  const reloadClientContacts = useCallback(async () => {
    if (receiver?.type === CLIENT_TYPE.USER) {
      const contacts = await loadClientContacts(receiver, isActiveReferencedOrder);
      if (contacts?.length !== 0) setClientContacts(contacts?.contacts);
    }
  }, [receiver, isActiveReferencedOrder]);

  useEffect(() => {
    if (!receiver) return;
    reloadClientAddresses();
    reloadClientContacts();
  }, [receiver, reloadClientAddresses, reloadClientContacts]);

  useEffect(() => {
    const user = appDataUser?.information;

    if (receiver?.type === CLIENT_TYPE.USER) {
      setSelectedContactId(user.id);
      setReceiverAddressType(ADDRESS_TYPE.COMPANY);
    } else if (receiver?.type === CLIENT_TYPE.USER || disableFacility) {
      setSelectedClientAddressId(receiver?.defaultAddress?.id);
      setSelectedFacilityAddress(showHUL ? data?.fittingType?.executingFacility : appDataUser?.facility);
    } else if (receiver?.type === CLIENT_TYPE.BASE_STORAGE) {
      setSelectedFacilityAddress(receiver?.address);
      setSelectedContactId(user.id);
      setReceiverAddressType(receiver?.address?.type);
    }
  }, [receiver, appDataUser, showHUL, data?.fittingType?.executingFacility, disableFacility]);

  //On change
  const onClientAddressChange = useCallback(address => {
    setSelectedClientAddressId(address?.id);
  }, []);

  const onFacilityAddressChange = useCallback(address => {
    setSelectedFacilityAddress(address);
  }, []);

  const onReceiverTypeChange = useCallback(
    type => {
      if (type === ADDRESS_TYPE.COMPANY && showHUL) {
        setSelectedFacilityAddress(data?.fittingType?.executingFacility);
      }
      setReceiverAddressType(type);
    },
    [showHUL, data],
  );

  const onContactChange = useCallback(contact => {
    setSelectedContactId(contact?.id);
  }, []);

  const onDeliveryMethodChange = option => {
    setSelectedDeliveryMethod(option);
  };
  const getAddressId = address => {
    return address?.id;
  };

  //Save
  const handleSubmit = async () => {
    if (useAdvanceContact == null) {
      setShowRequired(true);
      return;
    }

    setLoading(true);

    const selectedAddress =
      receiverAddressType === ADDRESS_TYPE.COMPANY ? selectedFacilityAddress : getSelectedAddress();
    const selectedContact = getSelectedContact();
    const addressAndContact = {
      contact: { id: selectedContact?.id, type: selectedContact?.type },
      address: {
        id: getAddressId(selectedAddress),
        type: receiverAddressType === ADDRESS_TYPE.COMPANY ? ADDRESS_TYPE.COMPANY : ADDRESS_TYPE.CLIENT,
      },
    };
    let response;
    const successMessage = translate("ADDRESSES_UPDATED_SUCCESSFULLY");
    const errorMessage = translate("NO_ADDRESS_OR_CONTACT_SELECTED");
    if (productList.length <= 0) {
      setLoading(false);
      onClose();
      return;
    }
    for (let i = 0; i < productList.length; i++) {
      if (!addressAndContact.address.id) {
        setLoading(false);
        setError({ errorMessage: errorMessage });
        return;
      }
      response = await orderService.updateDelivery(addressAndContact, productList[i].orderId, productList[i].id);
      if (showDeliveryMethods && receiverAddressType === ADDRESS_TYPE.CLIENT) {
        if (!selectedDeliveryMethod.id) {
          setLoading(false);
          setError({ errorMessage: translate("NO_DELIVERY_METHOD_SELECTED") });
          return;
        } else {
          await orderService.updateContent(productList[i].id, {
            ...productList[i],
            deliveryMethod: selectedDeliveryMethod,
          });
        }
      } else if (showDeliveryMethods && receiverAddressType === ADDRESS_TYPE.COMPANY) {
        await orderService.updateContent(productList[i].id, {
          ...productList[i],
        });
      }

      if (response.isOk()) {
        await orderService.updateCart(productList[i].orderId, productList[i].id);
        setMessage(successMessage);
        onClose();
      }
    }

    const clientResponse = await clientService.updateClient(receiver.id, {
      contactInformation: receiver.contactInformation,
      useAdvanceContact,
    });
    if (clientResponse.isOk()) {
      const orderInfo = await orderService.getOrder(orderId);
      setOrder(orderInfo.data);
    }

    setLoading(false);
    fetchOrders();
    onClose();
  };

  //Get
  const getSelectedAddress = useCallback(() => {
    if (selectedClientAddressId) return clientAddresses?.find(address => address.id === selectedClientAddressId);
  }, [clientAddresses, selectedClientAddressId]);

  function getSelectedContact() {
    if (receiver?.type === CLIENT_TYPE.BASE_STORAGE) {
      return { id: appDataUser.information.id, type: CONTACT_TYPE.PERSON };
    }
    cleanSelectedContactId();
    if (selectedContactId) return clientContacts?.find(contact => contact.id === selectedContactId);
  }

  //Removes encoded ¤ from selectedContactId if exist (¤ = %C2%A4);
  function cleanSelectedContactId() {
    if (selectedContactId) {
      const newselectedContactId = selectedContactId.split("%C2%A4");
      if (newselectedContactId.length >= 2) setSelectedContactId(newselectedContactId[1]);
    }
  }

  //Render
  return (
    <Modal title={translate("CHANGE_ADDRESS_ON_MULTIPLE")} visible={visible} onClose={onClose}>
      <section>
        {receiver?.type === CLIENT_TYPE.USER ? (
          <>
            <h2>
              <b>{translate("CHOOSE_DELIVERY_ADDRESS_AND_CONTACT")}</b>
            </h2>
            {!disableFacility && (
              <div className={styles.radioButtonGroup}>
                <RadioButton
                  label={translate("USER/UNIT")}
                  id="user"
                  name="address-type"
                  checked={receiverAddressType === ADDRESS_TYPE.CLIENT}
                  onChange={() => onReceiverTypeChange(ADDRESS_TYPE.CLIENT)}
                />
                {showHUL ? (
                  <RadioButton
                    label={data?.fittingType?.executingFacility?.facility?.label}
                    id="hul"
                    name="address-type"
                    onChange={() => onReceiverTypeChange(ADDRESS_TYPE.COMPANY)}
                    checked={receiverAddressType === ADDRESS_TYPE.COMPANY}
                  />
                ) : (
                  <RadioButton
                    label={translate("BASE_STORAGE")}
                    id="storage"
                    name="address-type"
                    onChange={() => onReceiverTypeChange(ADDRESS_TYPE.COMPANY)}
                    checked={receiverAddressType === ADDRESS_TYPE.COMPANY}
                  />
                )}
              </div>
            )}
            {receiverAddressType === CLIENT_TYPE.USER ? (
              <>
                <UserAddress
                  user={receiver}
                  addresses={clientAddresses}
                  selectedAddress={getSelectedAddress()}
                  onSelect={onClientAddressChange}
                  onReload={reloadClientAddresses}
                  required
                />
                {showDeliveryMethods && (
                  <DeliveryMethods
                    options={deliveryMethods}
                    selectedId={selectedDeliveryMethod?.id}
                    onSelect={onDeliveryMethodChange}
                  />
                )}
              </>
            ) : showHUL ? (
              <HULAddress address={data.fittingType.executingFacility} />
            ) : (
              <FacilityAddress
                onSelect={onFacilityAddressChange}
                preSelected={selectedFacilityAddress}
                disabled={showHUL}
                showHUL={showHUL}
                isActiveReferencedOrder={isActiveReferencedOrder}
              />
            )}
            <AdvanceContactInformation
              shouldContact={useAdvanceContact}
              onChange={setUseAdvanceContact}
              showRequired={showRequired}
            />
            {
              <ContactPerson
                user={receiver}
                contacts={clientContacts}
                selectedContact={getSelectedContact()}
                onSelect={onContactChange}
                onReload={reloadClientContacts}
                isActiveReferencedOrder={isActiveReferencedOrder}
                required
              />
            }
          </>
        ) : (
          <FacilityAddressInfo />
        )}
        <Button onClick={handleSubmit} isLoading={isLoading} customCssClass={styles.submitBtn}>
          {translate("UPDATE")}
        </Button>
      </section>
    </Modal>
  );
}

async function loadClientAddresses(user) {
  if (user) {
    const response = await clientService.getAddress(user.id);
    if (response.isOk()) return response.data;
  }
}

async function loadClientContacts(user, activeReferenceOrder) {
  if (user) {
    const response = await clientService.getContact(
      user.id,
      "",
      activeReferenceOrder?.fitting ? false : true,
      activeReferenceOrder?.fitting ? decodeURIComponent(activeReferenceOrder?.fitting?.prescriber?.id) : "",
    );
    if (response.isOk()) return response.data;
  }
}

AddressAndContactModal.propTypes = {
  visible: PropTypes.bool,
  address: PropTypes.object,
  productList: PropTypes.array,
  onClose: PropTypes.func,
  contact: PropTypes.object,
  disableFacility: PropTypes.bool,
  onNext: PropTypes.func,
  showHUL: PropTypes.bool,
  defaultBaseStorage: PropTypes.bool,
  data: PropTypes.object,
  showDeliveryMethods: PropTypes.bool,
  fetchOrders: PropTypes.func,
};

export default AddressAndContactModal;
