import { URL } from "@myloc/myloc-utils";
import { matchPath } from "react-router";
import store from "../reducers/rootReducer";

// Define types for parameters
interface Params {
  filter?: {
    from: string;
    amount: string;
  };
  [key: string]: any;
}

export const endpoint = (url: string, params: Params = {}, version = "/fsp/v1"): URL => {
  const content = new URLSearchParams(window.location.search);
  const state = store.getState();
  const root = process.env.REACT_APP_API_URL as string;

  const urlParams = matchPath<{ order: string; type: string; content: string }>(window.location.pathname, {
    path: "/orders/:order/:type/:content",
  });

  const clientParams = matchPath<{ client: string }>(window.location.pathname, {
    path: "/clients/:client",
  });

  const defaults: { [key: string]: any } = {
    order: urlParams?.params.order ?? state.appData.order?.id,
    content: urlParams?.params.content ?? content.get("contentid"),
    client: clientParams?.params.client,
  };

  const templates: { [key: string]: any } = {
    ...defaults,
    ...params,
  };

  // Ensure key is string and handle undefined template values
  const preparedUrl = url.replace(/{([^}]+)}/g, (_, key: string) => {
    return templates[key] !== undefined ? templates[key] : `{${key}}`; // If undefined, preserve placeholder
  });

  const uri = new URL(`${root}${version}${preparedUrl}`);

  if (params.filter) {
    uri.addParameter("from", params.filter.from);
    uri.addParameter("amount", params.filter.amount);
  }

  return uri;
};

const hasContent = (): boolean => {
  const params = matchPath<{ content: string }>(window.location.pathname, {
    path: "/orders/:order/:type/:content",
  });

  const content = new URLSearchParams(window.location.search);

  return Boolean(content.get("contentid") ?? params?.params.content);
};

export const api = {
  account: {
    authentication: () => endpoint("/authentication"),
    confirmAccessMessage: () => endpoint("/user/message"),
    login: () => endpoint("/authentication"),
    logout: () => endpoint("/authentication"),
    appData: () => endpoint("/authentication"),
    settings: () => endpoint("/user"),
    location: () => endpoint("/user/location"),
    password: () => endpoint("/password"),
    passwordRules: () => endpoint("/password/rules"),
    reset: () => endpoint("/password"),
    setUserSetting: (id: string) => endpoint("/user/settings/{userSettingId}/set-user-setting", { userSettingId: id }),
    getUserSetting: (id: string) => endpoint("/user/settings/{userSettingId}/get-user-setting", { userSettingId: id }),
  },
  baseStorage: {
    baseStorageBalance: () => endpoint("/base-storages/balances"),
    inventoryAction: (inventoryId: string) => endpoint("/inventories/{inventory}", { inventory: inventoryId }),
    inventoryLines: (lineId?: string) =>
      lineId ? endpoint("/inventories/lines/{line}", { line: lineId }) : endpoint("/inventories/lines"),
    move: (balanceId: string) => endpoint("/base-storages/balances/{balance}/move", { balance: balanceId }),
    scrap: () => endpoint("/base-storages/balances/scrap"),
    inventory: (facilityId?: string) =>
      facilityId
        ? endpoint("/base-storages/{facility}/inventories", { facility: facilityId })
        : endpoint("/inventories"),
    warehouseLocationsForBaseStorage: (facilityId: string) =>
      endpoint("/base-storages/{facility}/warehouses/locations", { facility: facilityId }),
  },
  client: {
    client: (clientId: string) => endpoint("/clients/{client}", { client: clientId }),
    clients: () => endpoint("/clients"),
    address: ({ clientId, addressId }: { clientId: string; addressId?: string }) =>
      !addressId
        ? endpoint("/clients/{client}/addresses", { client: clientId })
        : endpoint("/clients/{client}/addresses/{address}", { client: clientId, address: addressId }),
    contact: ({ clientId, contactId }: { clientId: string; contactId?: string }) =>
      !contactId
        ? endpoint("/clients/{client}/contacts", { client: clientId })
        : endpoint("/clients/{client}/contacts/{contact}", { client: clientId, contact: contactId }),
    holding: ({ clientId, inventoryId }: { clientId: string; inventoryId?: string }) =>
      !inventoryId
        ? endpoint("/clients/{client}/holding", { client: clientId })
        : endpoint("/clients/{client}/holding/{inventory}", { client: clientId, inventory: inventoryId }),
    holdingHistory: ({ clientId }: { clientId: string }) =>
      endpoint("/clients/{client}/holding/transactions", { client: clientId }),
    tasks: (clientId: string) => endpoint("/clients/{client}/tasks", { client: clientId }),
    orders: (clientId: string) => endpoint("/clients/{client}/web-orders", { client: clientId }),
    accessories: ({ clientId, holdingId }: { clientId: string; holdingId: string }) =>
      endpoint("/clients/{client}/holding/{holding}/accessories", { client: clientId, holding: holdingId }),
  },
  comflow: {
    closeAllComflowActiveTasks: (sessionId: string) =>
      endpoint("/sessions/comflow/{sessionId}/close-all-comflow-activities", { sessionId }),
    getMylocTokenLoginSessionData: () => endpoint("/sessions/get-myloc-token-login-session-data"),
  },
  customer: {
    customers: () => endpoint("/customers"),
  },
  deliveryMethod: {
    deliveryMethods: () => endpoint("/delivery-methods"),
  },
  deviation: {
    deviation: (deviationId?: string) =>
      deviationId ? endpoint("/deviations/{deviation}", { deviation: deviationId }) : endpoint("/deviations"),
    categories: () => endpoint("/deviations/categories"),
    references: (categoryId: string) =>
      endpoint("/deviations/categories/{category}/references", { category: categoryId }),
    files: (deviationId: string) => endpoint("/deviations/{deviation}/files", { deviation: deviationId }),
  },
  documents: {
    folders: () => endpoint("/company-guides/categories"),
    documents: () => endpoint("/company-guides"),
  },
  facility: {
    facility: (facilityId: string) => endpoint("/base-storages/{facility}", { facility: facilityId }),
    facilities: () => endpoint("/base-storages"),
  },
  files: (fileId?: string) =>
    fileId ? endpoint("/files/{file}", { file: fileId }, "/v1") : endpoint("/files", {}, "/v1"),
  messages: {
    messages: () => endpoint("/messages"),
    message: (id: string) => endpoint(`/messages/${id}`),
    receivers: () => endpoint("/messages/receivers"),
  },
  order: {
    orders: () => endpoint("/web-orders"),
    order: (orderId: string) => endpoint("/web-orders/{order}", { order: orderId }),
    category: () =>
      hasContent()
        ? endpoint("/web-orders/{order}/content/{content}/categories")
        : endpoint("/web-orders/functions/categories"),
    comments: () => endpoint("/web-orders/{order}/content/{content}/comments"),
    clientHoldings: (orderId: string, contentId?: string) =>
      contentId
        ? endpoint("/web-orders/{order}/content/{content}/client-holdings", { order: orderId, content: contentId })
        : endpoint("/web-orders/{order}/client-holdings", { order: orderId }),
    filter: () => endpoint(`/web-orders/filters`),
    delivery: (orderId: string, contentId?: string) =>
      contentId
        ? endpoint("/web-orders/{order}/content/{content}/delivery", { order: orderId, content: contentId })
        : endpoint("/web-orders/{order}/content/{content}/delivery"),
    cart: (orderId: string, contentId: string) =>
      endpoint("/web-orders/{order}/content/{content}", { order: orderId, content: contentId }),
    form: (orderId: string, contentId: string) =>
      endpoint("/web-orders/{order}/content/{content}/form", { order: orderId, content: contentId }),
    content: (contentId?: string) => {
      if (contentId) {
        return endpoint("/web-orders/{order}/content/{content}", { content: contentId });
      }

      return hasContent() ? endpoint("/web-orders/{order}/content/{content}") : endpoint("/web-orders/{order}/content");
    },
    createContentWithParent: (orderId: string) => endpoint("/web-orders/{order}/content", { order: orderId }),
    make: () => endpoint("/web-orders/{order}/content/{content}/makes"),
    accessories: (orderId: string, contentId: string) =>
      endpoint("/web-orders/{order}/content/{content}/accessories", { order: orderId, content: contentId }),
    files: (contentId: string) =>
      contentId
        ? endpoint("/web-orders/{order}/content/{content}/files", { content: contentId })
        : endpoint("/web-orders/{order}/content/{content}/files"),
    file: (fileId: string, contentId?: string) =>
      contentId
        ? endpoint("/web-orders/{order}/content/{content}/files/{file}", { file: fileId, content: contentId })
        : endpoint("/web-orders/{order}/content/{content}/files/{file}", { file: fileId }),
    deleteFile: (orderId: string, contentId: string, fileId: string) =>
      endpoint("/web-orders/{order}/content/{content}/files/{file}", {
        order: orderId,
        content: contentId,
        file: fileId,
      }),
    type: (orderId: string, contentId: string) =>
      endpoint("/web-orders/{order}/content/{content}/sub-content-types", { order: orderId, content: contentId }),
    line: (contentId: string) => endpoint("/web-orders/{order}/content/{content}/lines", { content: contentId }),
    send: (orderId: string) => endpoint("/web-orders/{order}", { order: orderId }),
  },
  pageContent: {
    content: () => endpoint("/page-content"),
  },
  product: {
    product: () => endpoint("/products"),
    suppliers: () => endpoint("/product-suppliers"),
    inventory: () => endpoint("/fixed-assets"),
    fixedAsset: ({ productId }: { productId: string }) => endpoint("/fixed-assets/{product}", { product: productId }),
    label: ({ productId }: { productId: string }) => endpoint("/fixed-assets/{product}/label", { product: productId }),
    fixedAssetAccessories: ({ fixedAssetId }: { fixedAssetId: string }) =>
      endpoint("/fixed-assets/{fixedAsset}/accessories", { fixedAsset: fixedAssetId }),
    logisticsOrder: ({ productId }: { productId: string }) =>
      endpoint("/fixed-assets/{product}/logistics-orders", { product: productId }),
    filters: () => endpoint("/products/filters"),
    productDetail: ({ productId }: { productId: string }) => endpoint(`/products/{product}`, { product: productId }),
    pageContents: ({ productId }: { productId: string }) =>
      endpoint("/products/{product}/page-contents", { product: productId }),
    reasonCodes: () => endpoint("/reason-codes"),
  },
  receive: {
    deliveries: () => endpoint("/deliveries"),
  },
  resourceGroup: {
    resources: (resourceGroupId: string) =>
      endpoint("/resource-groups/{resource}/resources", { resource: resourceGroupId }),
  },
  singleSignOn: {
    getUserInfo: () => {
      let port = window.location.port;

      if (port !== "80" && port !== "443" && port !== "") {
        port = ":" + port;
      }

      const url =
        window.location.protocol +
        "//" +
        window.location.hostname +
        port +
        process.env.REACT_APP_SSO_BASE_PATH +
        "?info=json";

      return new URL(url);
    },
  },
  webOrders: {
    content: ({ contentId }: { contentId: string }) =>
      endpoint(`/web-orders/{order}/content/{content}`, { content: contentId }),
    fittingTypes: () => endpoint(`/web-orders/fittings/types`),
    meetings: (meetingId?: string) =>
      meetingId
        ? endpoint(`/web-orders/fittings/meetings/{meeting}`, { meeting: meetingId })
        : endpoint(`/web-orders/fittings/meetings`),
    fittings: (fittingId?: string) =>
      fittingId ? endpoint(`/web-orders/fittings/{fitting}`, { fitting: fittingId }) : endpoint(`/web-orders/fittings`),
    contentFiles: (orderId: string, contentId: string) =>
      endpoint("/web-orders/{order}/content/{content}/files", { order: orderId, content: contentId }),
    files: (fittingId: string) => endpoint("/web-orders/fittings/{fitting}/files", { fitting: fittingId }),
    fittingMeeting: (fittingId: string, meetingId?: string) =>
      meetingId
        ? endpoint(`/web-orders/fittings/{fitting}/meetings/{meeting}`, { fitting: fittingId, meeting: meetingId })
        : endpoint(`/web-orders/fittings/{fitting}/meetings`, { fitting: fittingId }),
    deleteFile: (fittingId: string, fileId: string) =>
      endpoint("/web-orders/fittings/{fitting}/files/{file}", { fitting: fittingId, file: fileId }),
    fittingOrders: (fittingId: string) => endpoint("/web-orders/fittings/{fitting}/content", { fitting: fittingId }),
    createFittingComment: (fittingId: string) =>
      endpoint("/web-orders/fittings/{fitting}/comments", { fitting: fittingId }),
    deleteFittingComment: (commentId: string) =>
      endpoint("/web-orders/fittings/comments/{comment}", { comment: commentId }),
  },
  tasks: (task?: string) => (task ? endpoint(`/tasks/{task}`, { task }) : endpoint("/tasks")),
};

const allowRedirectionToAfterLogin = (desiredPage: string): boolean => {
  //Not allowed pages to restrict redirection to after login - only part of url is needed
  const PASSWORD = "/password";

  const DISALLOWED_PARTS_OF_PATH = [PASSWORD];

  //If returned value from findIndex >= 0, a disallowed part of path has been found among the DISALLOWED urls
  const posInDisallowedArray = DISALLOWED_PARTS_OF_PATH.findIndex(element => desiredPage.indexOf(element) >= 0);

  //Return allowed (true) if no positsion in array was found (-1)
  return posInDisallowedArray === -1;
};

export const myloc = {
  webpageUrl: new URL("https://myloc.se"),
  cookieIntegrityUrl: new URL("https://myloc.se/sv/gdpr/"),
  contactEmail: new URL("info@myloc.se"),
  contactPhone: new URL("040-6304643"),
  supportEmail: new URL("support.logistics@myloc.se"),
  address: {
    street: "Kärleksgatan 1A",
    postalNo: "211 45",
    city: "Malmö",
    county: "Skåne",
  },
};

export const HOS = {
  cookieIntegrityUrl: new URL("https://myloc.se/sv/gdpr/"),
  contactPhone: new URL("044-134245"),
  supportEmail: new URL("support.hjalpmedelscentrum@kristianstad.se"),
  address: {
    street: "Kabelvägen 17",
    postalNo: "291 62",
    city: "Kristianstad",
    county: "Skåne",
  },
};

export const pages = {
  allowRedirectionToAfterLogin,
};
