import { AnyAction } from "redux";
import {
  ADDRESS,
  CLEAR_ORDER_STATE,
  CLEAR_RECEIVER_MENU,
  CLEAR_STATE,
  CLIENT,
  COMPANY,
  CURRENT_STEP,
  FACILITY,
  INFORMATION,
  ORDER,
  ORDER_MOVE_ENABLED,
  RECEIVER_MENU,
  SET_IS_RECEIVE_DELIVERY_FOR_FIXED_ASSETS_ENABLED,
  USERNAME,
} from "./appDataVariables";

interface Address {
  addressRow1: string;
  addressRow2: string;
  postalNo: string;
  city: string;
  country: string;
  description: string;
}

interface Company {
  id: string;
  description: string;
}

interface Facility {
  id: string;
  description: string;
  value: string;
  category: {
    label: string;
    value: string;
  };
}

interface Information {
  firstName: string;
  surname: string;
  phoneNo: string;
  email: string;
  referenceId: string;
}

interface User {
  username: string;
  address: Address;
  company: Company;
  facility: Facility;
  information: Information;
}

interface AppState {
  order: any;
  orderMoveEnabled: boolean;
  isReceiveDeliveryForFixedAssetsEnabled: boolean;
  receiverMenu: any;
  user: User;
  currentStep: number;
}

const initialState: AppState = {
  order: null,
  orderMoveEnabled: false,
  isReceiveDeliveryForFixedAssetsEnabled: true,
  receiverMenu: null,
  user: {
    username: "",
    address: {
      addressRow1: "",
      addressRow2: "",
      postalNo: "",
      city: "",
      country: "",
      description: "",
    },
    company: {
      id: "",
      description: "",
    },
    facility: {
      id: "",
      description: "",
      value: "",
      category: { label: "", value: "" },
    },
    information: {
      firstName: "",
      surname: "",
      phoneNo: "",
      email: "",
      referenceId: "",
    },
  },
  currentStep: 0,
};

export const appDataReducer = (state: AppState = initialState, action: AnyAction): AppState => {
  switch (action.type) {
    case ORDER:
      return { ...state, order: action.order };
    case ORDER_MOVE_ENABLED:
      return Object.assign({}, state, { orderMoveEnabled: action.orderMoveEnabled });
    case SET_IS_RECEIVE_DELIVERY_FOR_FIXED_ASSETS_ENABLED:
      return Object.assign({}, state, {
        isReceiveDeliveryForFixedAssetsEnabled: action.isReceiveDeliveryForFixedAssetsEnabled,
      });
    case CLIENT:
      return { ...state, order: { ...state.order, receiver: action.client } };
    case USERNAME:
      return { ...state, user: { ...state.user, username: action.username } };
    case ADDRESS:
      return { ...state, user: { ...state.user, address: action.address } };
    case COMPANY:
      return { ...state, user: { ...state.user, company: action.company } };
    case FACILITY:
      return { ...state, user: { ...state.user, facility: action.facility } };
    case INFORMATION:
      return {
        ...state,
        user: {
          ...state.user,
          information: {
            ...action.information,
            id: action.information.id,
          },
        },
      };
    case RECEIVER_MENU:
      return { ...state, receiverMenu: action.receiverMenu };
    case CLEAR_STATE:
      return initialState;
    case CLEAR_ORDER_STATE:
      return { ...state, order: initialState.order };
    case CLEAR_RECEIVER_MENU:
      return { ...state, receiverMenu: initialState.receiverMenu };
    case CURRENT_STEP:
      return { ...state, currentStep: action.currentStep };
    default:
      return state;
  }
};
